.gallery-item {
    flex: 0 0 calc(33.333% - 2rem);
    margin: 1rem;
   
  }
  
  .gallery-item img {
    width: 100%;
    height: auto;
     
  }