.book {
  position: relative;
  border-radius: 10px;
  
  background-color: whitesmoke;
  -webkit-box-shadow: 1px 1px 12px #000;
  box-shadow: 1px 1px 12px #000;
  -webkit-transform: preserve-3d;
  -ms-transform: preserve-3d;
  transform: preserve-3d;
  -webkit-perspective: 2000px;
  perspective: 2000px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #000;
}

.cover {
  top: 0;
  position: absolute;
  background-color: lightgray;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  cursor: pointer;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-transform-origin: 0;
  -ms-transform-origin: 0;
  transform-origin: 0;
  -webkit-box-shadow: 1px 1px 12px #000;
  box-shadow: 1px 1px 12px #000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.book:hover .cover {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-transform: rotatey(-100deg);
  -ms-transform: rotatey(-100deg);
  transform: rotatey(-150deg);
}

p {
  font-size: 20px;
  font-weight: bolder;
}

.parking {
  background-image: linear-gradient(
      to left,
      rgba(22, 22, 235, 0.01),
      rgba(1, 9, 10, 0.9)
    ),
    url("../../assets/sunfour.jpg");

  /* background-repeat: no-repeat;
        background-size: cover; */
  height: auto;
  width: 100%;
  /* background-size: contain; */
  background-size: cover;

  background-size: 100% 100%;

  background-repeat: no-repeat;
}
