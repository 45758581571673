
.tulip-landing{
    background-image: linear-gradient(
        to left,
        rgba(49, 16, 234, 0.01),
        rgba(0, 9, 10, 0.9)
      ),
      url("../../assets/landingtulip.jpg");
     
    height: auto;
    width: 100%;
   
    background-size: cover;
  
    background-size: 100% 100%;
  
    background-repeat: no-repeat;


}


/* .newtulip {
  background-image: url("https://fieldedge.com/wp-content/themes/fieldedge/assets/dist/images/bg/partners-circle.png");
} */


