/* section {
    width: 600px;
    margin:0 auto;
  } */

  .tulip-food {
    display: flex;
    justify-content: space-between;
    position: relative;
    height: 230px;
    /* flex-direction: column; */
  }

  .tulip-food:nth-child(even) {
    flex-direction: row-reverse;
  }

  .icon {
    width: 150px;
    height: 150px;
    border: 3px solid rgb(228, 217, 235);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }


  .even .icon:after {
    content: '';
    width: 355px;
    border-bottom: solid 3px rgb(98, 15, 152);
    position: absolute;
    top: 240px;
    left: 115px;
    transform: rotate(30deg);
  }

  .odd:not(:last-child) .icon:after {
    content: '';
    width: 350px;
    border-bottom: solid 3px rgb(98, 15, 152);
    position: absolute;
    top: 195px;
    left: 125px;
    transform: rotate(-30deg);
  }
.fa-solid{
    font-size: 50px;
    color:black;
}
  .text {
    width: 240px;
    height: 150px;
    color: white;
  }